<template>
  <v-dialog
    v-model="dialog"
    :retain-focus="false"
    persistent
    :no-click-animation="true"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card class="bg-color-light b-radius-0 radius-nill">
      <v-app-bar
        dark
        color="#0EB5C5"
        fixed
        :height="$vuetify.breakpoint.mdAndUp ? '64' : ''"

      >
        <v-toolbar-title class="mt-n2 ml-2 d-flex align-center">
          <div class="mr-3">
            <hb-icon color="#FFFFFF">mdi-storefront</hb-icon>
          </div>
          <div class="mt-1 hb-font-header-3-medium">Integration Portal</div>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <hb-btn
          icon
          large-close
          class="mr-n2"
          hover-background-color="#2bd3e3"
          color="#ffffff"
          @click="closeDialog"
        >
          mdi-close
        </hb-btn>
      </v-app-bar>
      <div class="subscription-window-container" v-if="!isAppOpened">
        <div class="mr-6 mb-4" v-if="hasErrors">
          <hb-notification v-model="hasErrors" type="error">
            Something went wrong. Please try again!
          </hb-notification>
        </div>
        <div v-for="(app, index) in getNectarAppsWithNotification" :key="index">
          <div v-if="app.notify" class="mr-6 mb-4">
            <hb-notification
              type="success"
              @close="clearNotification(app)"
            >
              Your request regarding access has been processed for
              <span class="hb-font-body-medium"> {{ app.name.en }} </span>
              <template v-slot:actions>
                <div class="d-flex align-center notification-action">
                  <hb-link @click="openApp(app)" class="hb-default-font-size"
                    >Learn More</hb-link
                  >
                </div>
              </template>
            </hb-notification>
          </div>
        </div>
        <hb-page-header
          title="Discover"
          description="Explore and integrate with a variety of apps and services to enhance the efficiency of your property management operations."
        >
        </hb-page-header>

        <div class="pt-4">

          <!-- Tab view will be done in the phase 2 development of app subscription feature -->
          
          <!-- <hb-tabs v-model="activeTab">
            <v-tab
              v-for="(tab, index) in tabs"
              :key="'tab' + index"
              :ripple="false"
              :disabled="!tab.active"
            >
              {{ tab.title }}
            </v-tab>
          </hb-tabs>
          <v-divider></v-divider>  -->

          <HbTextField
            class="pt-5 pb-5"
            search
            width="300px"
            v-model="searchedApp"
            placeholder="Search"
          />

          <div v-if="screenLoader" class="page-loader">
            <loader color="#00b2ce" size="40px"></loader>
          </div>

          <div v-else class="pb-12">
            <v-row no-gutters v-if="getSearchedApps('available_apps').length">
              <template v-for="app in getSearchedApps('available_apps')">
                <v-col
                v-if="app.isSubscribable && app.hasPropertySubscription"
                cols="4"
                :key="app.id"
                >
                  <appListItem
                    @openApp="openApp"
                    :appDetails="app"
                    :isPending="isSubscriptionPending(app)"
                  ></appListItem>
                </v-col>
              </template>
            </v-row>
            <div v-else>
              <HbEmptyState class="mr-6" message="No apps listed!" />
            </div>
            <div class="mt-6 mb-3 hb-font-header-3-medium">
              Active Subscriptions
            </div>
            <v-row no-gutters v-if="getSearchedApps('installed_apps').length">
              <template v-for="app in getSearchedApps('installed_apps')">
                <v-col
                  v-if="app.isSubscribable && app.hasPropertySubscription"
                  cols="4"
                  :key="app.id"
                >
                  <appListItem
                    @openApp="openApp"
                    :appDetails="app"
                    :isSubscribed="true"
                    :subscribedProperties="getSubscribedProperties(app)"
                  ></appListItem>
                </v-col>
              </template>
            </v-row>
            <div v-else>
              <HbEmptyState class="mr-6" message="No active apps listed!" />
            </div>
          </div>

        </div>
      </div>
      <div v-if="isAppOpened && !screenLoader">
        <appDetails
          parentScreen="Discover"
          :appDetails="openedAppDetails"
          :applicationStatus="openedApplicationStatus(openedAppDetails)"
          :hbSubscribedProperties="subscribedProperties"
          @loadApplications="loadApplications()"
        ></appDetails>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "../../assets/api";
import AppListItem from "../applications/app_subscription/AppListItem.vue";
import appDetails from "../applications/app_subscription/AppDetails.vue";
import { EventBus } from "../../EventBus";
import { getPassthroughRequestDetails } from "../../../src/utils/common"
import { mapGetters, mapActions } from "vuex";
import Loader from "../assets/CircleSpinner.vue";
import Vue from "vue";

export default {
  name: "AppSubscriptionWindow",
  components: {
    AppListItem,
    appDetails,
    Loader,
  },
  props: {
    totalApplications: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    screenLoader: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasErrors: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dialog: true,
      appNotification: true,
      // tabs: [
      //   {
      //     title: "Discover",
      //     value: "discover",
      //     active: true,
      //   },
      //   {
      //     title: "My Integrations",
      //     value: "my-integrations",
      //     active: false,
      //   },
      //   {
      //     title: "My Apps",
      //     value: "my-apps",
      //     active: false,
      //   },
      // ],
      // activeTab: false,
      searchedApp: "",
      isAppSubscribed: false,
      isAppOpened: false,
      openedAppDetails: {},
      subscribedProperties: [],
    };
  },

  computed: {
    ...mapGetters({
      hbSubscribedApplications:
        "authenticationStore/getHbSubscribedApplications",
      applicationResponse: "authenticationStore/getApplicationEndpointResponse",
      developerPortalAppId: "authenticationStore/getDeveloperPortalAppId",
      getNectarAppsWithNotification: "authenticationStore/getNectarAppsWithNotification",
    }),

    appName() {
      return this.appData?.name ?? "App Dialog";
    },
  },

  watch: {
    // Used to get opened application details by combining installed and available applications
    applicationResponse: {
      handler(value) {
        let data = [
          ...value.available_apps, 
          ...value.installed_apps
        ];
        let appId = this.openedAppDetails.app_id;
        this.openedAppDetails = data?.find((app) => app.app_id === appId) || {};
      },
      deep: true,
    },
  },

  created() {
    this.$store.dispatch("navigationStore/setSettingsNavigationOnly", true);
    EventBus.$on("openAppDialog", this.clearSelectedComponent);
  },
  destroyed() {
    this.$store.dispatch("navigationStore/setSettingsNavigationOnly", false);
    EventBus.$off("openAppDialog", this.clearSelectedComponent);
  },
  methods: {
    ...mapActions({
      setNectarApplications: "authenticationStore/setNectarApplications",
      updateNectarNotifications: "authenticationStore/updateNectarNotifications",
    }),
    getSearchedApps(type) {
      if (this.searchedApp) {
        let searchedItems = this.totalApplications?.[type]?.filter(
          (app) =>
            app?.name?.en
              ?.toLowerCase()
              ?.includes(this.searchedApp?.toLowerCase())
        );
        return searchedItems;
      } else return this.totalApplications?.[type] ?? [];
    },

    openedApplicationStatus(app) {
      if (this.isAppSubscribed) {
        return "subscribed";
      } else if (this.isSubscriptionPending(app)) {
        return "pending";
      }
    },
    isSubscriptionPending(app) {
      let isPending = app?.properties?.every(
        (property) => property.status === "pending"
      );
      let isAppUnsubscribed = this.hbSubscribedApplications.some(
        (application) => application.app_id === app.app_id
      );
      return isPending && !isAppUnsubscribed;
    },

    closeDialog() {
      this.dialog = false;
      this.setNectarApplications();
      EventBus.$emit("closeAppDialog");
    },

    getSubscribedProperties(app) {
      let subscribedApp = this.hbSubscribedApplications.find(
        application => application.app_urn === app.app_id
      );
      return subscribedApp ? subscribedApp.properties : [];
    },

    async openApp(app) {
      this.isAppOpened = true;
      this.openedAppDetails = app;
      this.subscribedProperties = [];
      this.subscribedProperties = await this.getSubscribedProperties(app);
      this.isAppSubscribed = !!this.totalApplications?.installed_apps?.find(
        (appDetail) => appDetail.name == app.name
      );
      if(app.notify) await this.clearNotification(app);
    },
    loadApplications() {
      this.isAppOpened = false;
      this.openedAppDetails = {};
      this.isAppSubscribed = false;
    },
    async clearNotification(app) {
      await this.updateNectarNotifications({app_id: app.app_id, notify: false})

      let request = getPassthroughRequestDetails(
        this.developerPortalAppId, 
        api.PASSTHROUGH_DEVPORTAL_ENDPOINT
      )
      let requestBody = { notify: false };

      try {
        await Vue.http.put(`${request.uri}${app.app_id}/`, requestBody, {
          headers: request.headers,
        });
        await this.setNectarApplications();
      } catch (e) {
        await this.updateNectarNotifications({app_id: app.app_id, notify: true})
        console.log(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.subscription-window-container {
  padding: 90px 0 0 28px;
}
.bg-color-light {
  background-color: #f9fafb;
}
.skeleton-loader {
  border: 1px solid #dfe3e8;
}
.notification-action {
  margin-top: 10px;
}
.page-loader {
  display: flex;
  height: calc(100vh - 500px);
  height: calc(100svh - 500px);
  align-items: center;
  justify-content: center;
}
.radius-nill {
  border-radius: 0px !important;
}
</style>
